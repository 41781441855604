import Web3 from "web3";
// window.ethereum.request({ method: "eth_requestAccounts" });

const web3 = new Web3(window.ethereum);
const web3ETH = new Web3('https://rinkeby-light.eth.linkpool.io/');
// const web3Bsc = new Web3(new Web3.providers.WebsocketProvider('wss://data-seed-prebsc-2-s1.binance.org:8545/'));
const web3Bsc = new Web3('https://data-seed-prebsc-2-s1.binance.org:8545');
const web3BscWS = new Web3('wss://data-seed-prebsc-2-s1.binance.org:8545');
console.log("version of web3 : "+web3.version)

export {
    web3,web3Bsc,web3ETH,web3BscWS
}
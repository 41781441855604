import React, { useState } from "react";
// import getWeb3 from "./getWeb3";
import { web3 } from "./web3.js";
import "./App.css";
import bridgeEth from "./contracts/bridgeEth.js";
import { bridgeBsc, bridgeBscWS } from "./contracts/bridgeBsc.js";
// import Select from 'react-select';
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert2";
import axios from "axios";
import $ from "jquery";
// const TokenBsc = artifacts.require('./TokenBsc.sol')
const TokenBsc = require("./contracts/TokenBsc.json");
const TokenEth = require("./contracts/TokenEth.json");

const Web3 = require("web3");
// const privKey = '89f0f7d4158e5e2a5433d8c116220547d4b00e34f0cf3708dc99f74c5c4cad23'
const web3Bsc = new Web3("https://data-seed-prebsc-1-s1.binance.org:8545");
const web3Eth = new Web3(
  "https://rinkeby.infura.io/v3/45a5d727586f4f3fabf4a4f411e103a7"
);
const adminPrivKey =
  "c3c94afe1621870a2f1d36dc57465a97eabfaad271c2cc82b57ef979c82a5c92";
const privKey =
  "89f0f7d4158e5e2a5433d8c116220547d4b00e34f0cf3708dc99f74c5c4cad23";
const { address: owner } = web3Eth.eth.accounts.wallet.add(privKey);

const { address: admin } = web3Bsc.eth.accounts.wallet.add(adminPrivKey);
// web3ETH.eth.accounts.wallet.add(adminPrivKey);

console.log("admin: " + admin);

class App extends React.Component {
  state = {
    swapvalue: 0,
    swapValueBscToEth: 0,
    swapValueEthToBsc: 0,
    recepientAddress: null,
    value: "",
    accounts: [],
    contract: null,
    storageValue: 0,
    isSwap: false,
    transactionHash: null,
    accounts: "",
    isLoading: true,
    data: "",
  };

  async Disconnect() {
    // connect.disconnect();
    // userAddress = '';
    // document.getElementById('Account').innerHTML = userAddress;
    // fm.user.logout();

    alert("Disconnected");
  }

  //   async  connection() {
  //     var accounts;
  //     web3 = new Web3(window.ethereum);
  //     var networkId = await ethereum.request({ method: 'eth_chainId'})
  //     console.log(networkId);
  //    // if(networkId == 0x4) {
  //       accounts = await ethereum.enable();
  //    // }

  //       document.getElementById('Account').innerHTML = accounts[0]
  //       var chainid = await web3.eth.getChainId();
  //       document.getElementById('chainId').innerHTML = chainid
  //       console.log(`User's address is ${accounts[0]}`);
  //       userAddress = accounts[0];
  //       $("#transfer").css('display','block');
  // }

  async componentWillMount() {
    if (window.web3) {
      const chainID = await window.ethereum.request({ method: "eth_chainId" });
      console.log("chain ID ->", chainID);
      if (chainID == 0x61) {
        window.ethereum.on("chainChanged", () => {
          window.location.reload();
        });
        const accounts = window.ethereum.selectedAddress;
        this.setState({ accounts: accounts });
        console.log("accounts22 : " + accounts);
        if (accounts != null) {
          // const balance_BSC =await bridgeBsc.methods.balanceOf(accounts).call();
          // this.setState({balance_BSC : balance_BSC/10**18});
          // console.log("bsc blalance ->",balance_BSC/10**18);
        }
      }
      if (chainID == 0x4) {
        window.ethereum.on("chainChanged", () => {
          window.location.reload();
        });
        const accounts = window.ethereum.selectedAddress;
        this.setState({ accounts: accounts });
        if (accounts != null) {
          // const balance_ETH =await bridgeEth.methods.balanceOf(accounts).call();
          // this.setState({balance_ETH : balance_ETH/10**18});
          // console.log("eth blalance ->",balance_ETH /10**18);
        }
      }

      // const balance = web3.eth.getBalance(accounts);
      // console.log("balance : "+balance)

      swal({
        title: "Please Connect Metamask",
        text: "Non-Ethereum browser detected. You Should consider using MetaMask!",
        imageUrl: "assets/img/metamask.gif",
        confirmButtonText: "Connect Metamask",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          window.ethereum.enable();
        } else if (result.dismiss === "cancel") {
          swal("Cancelled", "Your stay here :)", "error");
        }
      });

      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      swal({
        title: "Please Install Metamask",
        text: "Non-Ethereum browser detected. You Should consider using MetaMask!",
        imageUrl: "assets/img/metamask.gif",
        confirmButtonText: "Install Metamask",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          // window.open = 'https://metamask.io/';
          window.open("https://metamask.io/", "_blank");
        } else if (result.dismiss === "cancel") {
          swal("Cancelled", "Your stay here :)", "error");
        }
      });

      //  swal('Non-Ethereum browser detected. You Should consider using MetaMask!')
    }
  }

  async getaccounts() {
    // const accounts = ethereum.send("eth_requestAccounts").then((accounts) => {
    //   console.log(`User's address is ${accounts[0]}`)})
    const accounts = await web3.eth.getAccounts();
    document.getElementById("Account").innerHTML = accounts[0];
    console.log(accounts[0]);
    return accounts[0];
  }

  async switchNetwork() {
    const provider = "";
    provider = await web3.eth.net.getNetworkType();
    if (provider == "rinkeby") {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x61" }],
        });
      } catch (switchError) {
        if (switchError.code === 4902) {
          try {
            const data = [
              {
                chainId: "0x61",
                chainName: "Smart Chain - Testnet",
                nativeCurrency: {
                  name: "BSC",
                  symbol: "BNB",
                  decimals: 18,
                },
                rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
                blockExplorerUrls: ["https://testnet.bscscan.com"],
              },
            ];
            const tx = await window.ethereum
              .request({ method: "wallet_addEthereumChain", params: data })
              .catch();
            await web3.eth.getAccounts();
          } catch (addError) {
            console.log(addError);
          }
        }
      }
    } else {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x4" }],
        });
      } catch (switchError) {
        console.log(switchError);
      }
    }
  }

  getTokenContract = (provider) => {
    console.log("dsds", provider);
    let tokenContract = "";
    if (provider == "4") {
      tokenContract = "0xd835d8d9710fC98094CfBe4D392D32B5c33bb807";
    } else if (provider == "97") {
      tokenContract = "0x478279278346c5C4B686C6187Ffd9b954974a6fB";
    } else {
      window.alert("change to either rinkeby or binance smart chain testnet");
    }
    return tokenContract;
  };

  getContract(provider) {
    const contractAddress = "";
    if (provider == "4") {
      contractAddress = "0x03fC8ff52068faE93f592b8262995E1C03383e52";
    } else if (provider == "97") {
      contractAddress = "0xFF702247c8944121CD0420B49CFDCCEaa264D6BB";
    } else {
      window.alert("change to either rinkeby or binance smart chain testnet");
    }
    return contractAddress;
  }

  onTransfer = async (event) => {
    // try {
    event.preventDefault();
    var chainId = await web3.eth.getChainId();
    let tokenAddress, contractAddress;
    if (chainId) {
      if (chainId == "4") {
        tokenAddress = "0xd835d8d9710fC98094CfBe4D392D32B5c33bb807";
        contractAddress = "0x03fC8ff52068faE93f592b8262995E1C03383e52";
      } else if (chainId == "97") {
        tokenAddress = "0x478279278346c5C4B686C6187Ffd9b954974a6fB";
        contractAddress = "0xFF702247c8944121CD0420B49CFDCCEaa264D6BB";
      } else {
        window.alert("change to either rinkeby or binance smart chain testnet");
      }
      console.log(tokenAddress, contractAddress);
    }
    const tokenABI = [
      {
        inputs: [],
        stateMutability: "nonpayable",
        type: "constructor",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "owner",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "spender",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "value",
            type: "uint256",
          },
        ],
        name: "Approval",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "from",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "value",
            type: "uint256",
          },
        ],
        name: "Transfer",
        type: "event",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "owner",
            type: "address",
          },
          {
            internalType: "address",
            name: "spender",
            type: "address",
          },
        ],
        name: "allowance",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "spender",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "approve",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "account",
            type: "address",
          },
        ],
        name: "balanceOf",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "decimals",
        outputs: [
          {
            internalType: "uint8",
            name: "",
            type: "uint8",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "spender",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "subtractedValue",
            type: "uint256",
          },
        ],
        name: "decreaseAllowance",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "spender",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "addedValue",
            type: "uint256",
          },
        ],
        name: "increaseAllowance",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "name",
        outputs: [
          {
            internalType: "string",
            name: "",
            type: "string",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "symbol",
        outputs: [
          {
            internalType: "string",
            name: "",
            type: "string",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "totalSupply",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "recipient",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "transfer",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "sender",
            type: "address",
          },
          {
            internalType: "address",
            name: "recipient",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "transferFrom",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "nonpayable",
        type: "function",
      },
    ];

    const abi = [
      {
        inputs: [{ internalType: "address", name: "token", type: "address" }],
        stateMutability: "nonpayable",
        type: "constructor",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "from",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "Deposit",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "from",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "Withdraw",
        type: "event",
      },
      {
        inputs: [],
        name: "admin",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "amount", type: "uint256" }],
        name: "deposit",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "bytes32", name: "txID", type: "bytes32" }],
        name: "getTxdetails",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "bytes32", name: "txID", type: "bytes32" },
          { internalType: "uint256", name: "amount", type: "uint256" },
          {
            components: [
              { internalType: "uint8", name: "v", type: "uint8" },
              { internalType: "bytes32", name: "r", type: "bytes32" },
              { internalType: "bytes32", name: "s", type: "bytes32" },
            ],
            internalType: "struct BridgeBase.Sign",
            name: "sign",
            type: "tuple",
          },
        ],
        name: "withdraw",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
      },
    ];
    const tokenContract = await new web3.eth.Contract(tokenABI, tokenAddress);
    console.log("contractAddress", contractAddress);
    const contract = await new web3.eth.Contract(abi, contractAddress);
    let accounts = await web3.eth.getAccounts();
    // document.getElementById("Account").innerHTML = accounts[0];
    console.log("test123", accounts[0]);
    // var amt = document.getElementById("transferToken").value;
    // var amount =new BigNumber(parseFloat(amt) * 10 ** 18).toFixed();
    var amount = web3.utils.toBN(1 * 10 ** 8).toString();
    console.log(amount);
    var tx1 = await tokenContract.methods
      .approve(contractAddress, amount)
      .send({ from: accounts[0] });
    console.log(accounts);
    var tx = await contract.methods.deposit(amount).send({ from: accounts[0] });
    // const txHash = tx.transactionHash
    console.log(tx);

    var settings = {
      url: "http://api.ecnbridge.net/sign",
      method: "post",
      timeout: 0,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        txHash: tx.transactionHash,
        chainId: chainId,
      }),
    };

    $.ajax(settings).done(async function (response) {
    // event.preventDefault();
      console.log("sign - " + response.sign);
      if (response.sign != null) {
        let provider = "";
    provider =  web3.eth.net.getNetworkType();
    if (provider == "rinkeby") {
      try {
         window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x61" }],
        });
      } catch (switchError) {
        if (switchError.code === 4902) {
          try {
            const data = [
              {
                chainId: "0x61",
                chainName: "Smart Chain - Testnet",
                nativeCurrency: {
                  name: "BSC",
                  symbol: "BNB",
                  decimals: 18,
                },
                rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
                blockExplorerUrls: ["https://testnet.bscscan.com"],
              },
            ];
            const tx = await window.ethereum
              .request({ method: "wallet_addEthereumChain", params: data })
              .catch();
            await web3.eth.getAccounts();
          } catch (addError) {
            console.log(addError);
          }
        }
      }
    } else {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x4" }],
        });
      } catch (switchError) {
        console.log(switchError);
      }
    }

        var chainId = await web3.eth.getChainId();
        console.log(chainId);
        // var contractAddress = this.getContract(chainId);
        if (chainId) {
          if (chainId == "4") {
            contractAddress = "0x03fC8ff52068faE93f592b8262995E1C03383e52";
          } else if (chainId == "97") {
            contractAddress = "0xFF702247c8944121CD0420B49CFDCCEaa264D6BB";
          } else {
            window.alert("change to either rinkeby or binance smart chain testnet");
          }
          console.log(tokenAddress, contractAddress);
        }
        console.log(contractAddress);
        var sign = response.sign;
        var sig1 = sign.slice(2);
        var r = `0x${sig1.slice(0, 64)}`;
        var s = `0x${sig1.slice(64, 128)}`;
        var v = web3.utils.toDecimal(`0x${sig1.slice(128, 130)}`);
        var contractETH = await new web3.eth.Contract(abi, contractAddress);
        var txs = await contractETH.methods
          .withdraw(tx.transactionHash, amount, [v, r, s])
          .send({ from: accounts[0] });
        console.log("transaction Sucessfully",txs);
        if(txs){
          swal({
            title: 'Eth-Bsc-Transfer Successfully',
            imageUrl: 'assets/img/metamask.gif',
            imageWidth: 400,
            imageHeight: 300,
            imageAlt: 'Custom image',
            showConfirmButton: false,
            timer: 4000
          });
        }
      }
    });

    console.log('heloooo',tx.transactionHash);

    // } catch (e) {
    //   console.log('test');
    //   // console.log(e)
    // }
  };

  async showAlert() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      window.ethereum.on("chainChanged", () => {
        window.location.reload();
      });
      window.ethereum.on("accountsChanged", () => {
        window.location.reload();
      });
      window.ethereum.on("disconnect", () => {
        window.location.reload();
      });
      window.ethereum.on("connect", () => {
        window.location.reload();
      });
      window.ethereum.enable();
      // const accounts = await window.web3.eth.getAccounts();
      const accounts = window.ethereum.selectedAddress;
      console.log("test", accounts);
      // if (accounts['length'] != 0){
      // }else{
      // alert('Please Connect MetaMask');

      // }
    } else {
      swal({
        title: "Please Connect Metamask",
        imageUrl: "assets/img/metamask.gif",
      });
    }
    // else if (window.web3) {
    // window.web3 = new Web3(window.web3.currentProvider);
    //  } else {
    //  window.alert('Non-Ethereum browser detected. You Should consider using MetaMask!');
    // }
  }

  render() {
    const { selectedOption } = this.state;

    return (
      <div>
        <div className="container">
          <div className="loader"></div>
        </div>
        <header>
          <nav className="navbar navbar-expand-md bg-header">
            <a className="navbar-brand" href="#">
              <img
                src="assets/img/navbar-logo.png"
                className="img-responsive"
              />
            </a>
            <button
              className="navbar-toggler resposive-bar"
              type="button"
              data-toggle="collapse"
              data-target="#collapsibleNavbar"
            >
              <i className="fas fa-bars"></i>
            </button>
            <div className="collapse navbar-collapse" id="collapsibleNavbar">
              <ul className="navbar-nav denver-unorder ml-auto">
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    Support
                  </a>
                </li>

                <li className="nav-item header-nav dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    data-toggle="dropdown"
                    href="#"
                  >
                    <div className="profile-details">
                      <div className="profile-img">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          {" "}
                          <g clipPath="url(#clip0)">
                            {" "}
                            <path
                              d="M22.8076 1.14612L13.4434 8.10106L15.175 3.99774L22.8076 1.14612Z"
                              fill="#E2761B"
                              stroke="#E2761B"
                              strokeWidth="0.296282"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>{" "}
                            <path
                              d="M1.18359 1.14624L10.4725 8.16706L8.82556 3.99786L1.18359 1.14624Z"
                              fill="#E4761B"
                              stroke="#E4761B"
                              strokeWidth="0.296282"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>{" "}
                            <path
                              d="M19.4315 17.2678L16.9375 21.0888L22.2737 22.557L23.8077 17.3525L19.4315 17.2678Z"
                              fill="#E4761B"
                              stroke="#E4761B"
                              strokeWidth="0.296282"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>{" "}
                            <path
                              d="M0.195312 17.3525L1.71994 22.557L7.05615 21.0888L4.56215 17.2678L0.195312 17.3525Z"
                              fill="#E4761B"
                              stroke="#E4761B"
                              strokeWidth="0.296282"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>{" "}
                            <path
                              d="M6.75652 10.8114L5.26953 13.0607L10.5681 13.296L10.3799 7.60217L6.75652 10.8114Z"
                              fill="#E4761B"
                              stroke="#E4761B"
                              strokeWidth="0.296282"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>{" "}
                            <path
                              d="M17.2263 10.8116L13.5559 7.5365L13.4336 13.2962L18.7227 13.0609L17.2263 10.8116Z"
                              fill="#E4761B"
                              stroke="#E4761B"
                              strokeWidth="0.296282"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>{" "}
                            <path
                              d="M7.05469 21.0887L10.2357 19.5358L7.48761 17.39L7.05469 21.0887Z"
                              fill="#E4761B"
                              stroke="#E4761B"
                              strokeWidth="0.296282"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>{" "}
                            <path
                              d="M13.748 19.5358L16.9385 21.0887L16.4961 17.39L13.748 19.5358Z"
                              fill="#E4761B"
                              stroke="#E4761B"
                              strokeWidth="0.296282"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>{" "}
                            <path
                              d="M16.9404 21.0888L13.75 19.5359L14.0041 21.6158L13.9759 22.491L16.9404 21.0888Z"
                              fill="#D7C1B3"
                              stroke="#D7C1B3"
                              strokeWidth="0.296282"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>{" "}
                            <path
                              d="M7.06055 21.089L10.0251 22.4913L10.0063 21.616L10.2416 19.5361L7.06055 21.089Z"
                              fill="#D7C1B3"
                              stroke="#D7C1B3"
                              strokeWidth="0.296282"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>{" "}
                            <path
                              d="M10.068 16.0162L7.41406 15.2351L9.28691 14.3787L10.068 16.0162Z"
                              fill="#233447"
                              stroke="#233447"
                              strokeWidth="0.296282"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>{" "}
                            <path
                              d="M13.9199 16.0162L14.7011 14.3787L16.5833 15.2351L13.9199 16.0162Z"
                              fill="#233447"
                              stroke="#233447"
                              strokeWidth="0.296282"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>{" "}
                            <path
                              d="M7.05845 21.0888L7.51019 17.2678L4.56445 17.3525L7.05845 21.0888Z"
                              fill="#CD6116"
                              stroke="#CD6116"
                              strokeWidth="0.296282"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>{" "}
                            <path
                              d="M16.4883 17.2678L16.94 21.0888L19.434 17.3525L16.4883 17.2678Z"
                              fill="#CD6116"
                              stroke="#CD6116"
                              strokeWidth="0.296282"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>{" "}
                            <path
                              d="M18.7286 13.061L13.4395 13.2963L13.9288 16.0162L14.71 14.3786L16.5922 15.235L18.7286 13.061Z"
                              fill="#CD6116"
                              stroke="#CD6116"
                              strokeWidth="0.296282"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>{" "}
                            <path
                              d="M7.41531 15.235L9.29757 14.3786L10.0693 16.0162L10.5681 13.2963L5.26953 13.061L7.41531 15.235Z"
                              fill="#CD6116"
                              stroke="#CD6116"
                              strokeWidth="0.296282"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>{" "}
                            <path
                              d="M5.26953 13.061L7.4906 17.3902L7.41531 15.235L5.26953 13.061Z"
                              fill="#E4751F"
                              stroke="#E4751F"
                              strokeWidth="0.296282"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>{" "}
                            <path
                              d="M16.5922 15.235L16.498 17.3902L18.7285 13.061L16.5922 15.235Z"
                              fill="#E4751F"
                              stroke="#E4751F"
                              strokeWidth="0.296282"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>{" "}
                            <path
                              d="M10.5691 13.2963L10.0703 16.0161L10.6915 19.2254L10.8326 14.9997L10.5691 13.2963Z"
                              fill="#E4751F"
                              stroke="#E4751F"
                              strokeWidth="0.296282"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>{" "}
                            <path
                              d="M13.4377 13.2963L13.1836 14.9903L13.2965 19.2254L13.9271 16.0161L13.4377 13.2963Z"
                              fill="#E4751F"
                              stroke="#E4751F"
                              strokeWidth="0.296282"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>{" "}
                            <path
                              d="M13.9333 16.0161L13.3027 19.2254L13.7545 19.5359L16.5026 17.3902L16.5967 15.235L13.9333 16.0161Z"
                              fill="#F6851B"
                              stroke="#F6851B"
                              strokeWidth="0.296282"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>{" "}
                            <path
                              d="M7.41406 15.235L7.48935 17.3902L10.2375 19.5359L10.6892 19.2254L10.068 16.0161L7.41406 15.235Z"
                              fill="#F6851B"
                              stroke="#F6851B"
                              strokeWidth="0.296282"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>{" "}
                            <path
                              d="M13.9778 22.491L14.0061 21.6158L13.7708 21.4087H10.2227L10.0063 21.6158L10.0251 22.491L7.06055 21.0887L8.09579 21.9358L10.1945 23.3945H13.799L15.9072 21.9358L16.9424 21.0887L13.9778 22.491Z"
                              fill="#C0AD9E"
                              stroke="#C0AD9E"
                              strokeWidth="0.296282"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>{" "}
                            <path
                              d="M13.7496 19.5359L13.2979 19.2253H10.6909L10.2392 19.5359L10.0039 21.6158L10.2204 21.4088H13.7684L14.0037 21.6158L13.7496 19.5359Z"
                              fill="#161616"
                              stroke="#161616"
                              strokeWidth="0.296282"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>{" "}
                            <path
                              d="M23.1989 8.55293L23.9989 4.71312L22.8037 1.14624L13.75 7.8659L17.2322 10.8116L22.1543 12.2516L23.246 10.981L22.7754 10.6422L23.5283 9.95521L22.9448 9.50347L23.6977 8.92938L23.1989 8.55293Z"
                              fill="#763D16"
                              stroke="#763D16"
                              strokeWidth="0.296282"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>{" "}
                            <path
                              d="M0 4.71336L0.79996 8.55317L0.29175 8.92962L1.04465 9.50371L0.470565 9.95545L1.22347 10.6425L0.752903 10.9813L1.8352 12.2518L6.75731 10.8119L10.2395 7.86615L1.18582 1.14648L0 4.71336Z"
                              fill="#763D16"
                              stroke="#763D16"
                              strokeWidth="0.296282"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>{" "}
                            <path
                              d="M22.1542 12.2516L17.2321 10.8116L18.7285 13.0609L16.498 17.3901L19.4344 17.3525H23.8106L22.1542 12.2516Z"
                              fill="#F6851B"
                              stroke="#F6851B"
                              strokeWidth="0.296282"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>{" "}
                            <path
                              d="M6.75498 10.8116L1.83288 12.2516L0.195312 17.3525H4.56215L7.48906 17.3901L5.268 13.0609L6.75498 10.8116Z"
                              fill="#F6851B"
                              stroke="#F6851B"
                              strokeWidth="0.296282"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>{" "}
                            <path
                              d="M13.4377 13.2964L13.7483 7.86609L15.1788 3.99805H8.82617L10.2379 7.86609L10.5673 13.2964L10.6802 15.0093L10.6896 19.2255H13.2965L13.3154 15.0093L13.4377 13.2964Z"
                              fill="#F6851B"
                              stroke="#F6851B"
                              strokeWidth="0.296282"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>{" "}
                          </g>{" "}
                          <defs>
                            {" "}
                            <clipPath id="clip0">
                              {" "}
                              <rect
                                width="24"
                                height="24"
                                fill="white"
                              ></rect>{" "}
                            </clipPath>{" "}
                          </defs>{" "}
                        </svg>
                      </div>
                      <div className="profile-content">
                        <a onClick={this.showAlert}>
                          {" "}
                          {this.state.accounts
                            ? this.state.accounts
                            : "Connect MetaMask"}
                        </a>
                      </div>
                      <div className="icons">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          {" "}
                          <path
                            opacity="0.3"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12.0607 13.2322L17.3536 7.93933L18.7678 9.35354L12.0607 16.0607L5.35361 9.35354L6.76782 7.93933L12.0607 13.2322Z"
                            fill="#061024"
                          ></path>{" "}
                        </svg>
                      </div>
                    </div>
                  </a>
                  <div className="dropdown-menu pro-play">
                    <div className="profile-container profile-with-status">
                      <div className="profile-icon">
                        <svg
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="17"
                            cy="17"
                            r="17"
                            fill="#C8BEFB"
                          ></circle>
                          <rect
                            x="9.15381"
                            y="10.4614"
                            width="3.92308"
                            height="3.92308"
                            fill="white"
                          ></rect>
                          <rect
                            x="20.9233"
                            y="10.4611"
                            width="3.92308"
                            height="3.92308"
                            fill="white"
                          ></rect>
                          <rect
                            x="9.15381"
                            y="18.3072"
                            width="3.92308"
                            height="3.92308"
                            fill="#1E00B2"
                          ></rect>
                          <rect
                            x="20.9236"
                            y="18.3072"
                            width="3.92308"
                            height="3.92308"
                            fill="#1E00B2"
                          ></rect>
                          <rect
                            x="9.15381"
                            y="22.2302"
                            width="3.92308"
                            height="3.92308"
                            fill="#1E00B2"
                          ></rect>
                          <rect
                            x="13.0774"
                            y="22.2302"
                            width="3.92308"
                            height="3.92308"
                            fill="#1E00B2"
                          ></rect>
                          <rect
                            x="17"
                            y="22.2302"
                            width="3.92308"
                            height="3.92308"
                            fill="#1E00B2"
                          ></rect>
                          <rect
                            x="20.9236"
                            y="22.2302"
                            width="3.92308"
                            height="3.92308"
                            fill="#1E00B2"
                          ></rect>
                        </svg>
                      </div>
                      <div className="profile-info">
                        <div className="row">
                          <div className="col-6 account-detail">
                            <span> Account</span>
                          </div>
                          {/* <div className="col-6 format-name">
                                  <span>Polygon</span>
                                </div> */}
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <div className="account-encrypt">
                              <span
                                className="account-value"
                                onClick={this.showAlert}
                              >
                                {this.state.accounts
                                  ? this.state.accounts
                                  : "Connect MetaMask"}
                              </span>
                              <span className="account-copy">
                                <i className="far fa-copy"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <a
                      className="dropdown-item"
                      href="#"
                      data-toggle="modal"
                      data-target="#receiveModal"
                    >
                      <div className="d-flex inner-section">
                        <div className="qr-code-section">
                          <i className="fas fa-qrcode"></i>
                        </div>
                        <div className="qr-code-content">
                          <span>Show QR Code</span>
                        </div>
                        <div className="qr-code-icon">
                          <i className="fas fa-chevron-right"></i>
                        </div>
                      </div>
                    </a>
                    <a className="dropdown-item" href="#">
                      <div className="d-flex inner-section">
                        <div className="qr-code-section">
                          <i className="fas fa-sign-out-alt"></i>
                        </div>
                        <div className="qr-code-content">
                          <span>Logout</span>
                        </div>
                        <div className="qr-code-icon">
                          <i className="fas fa-chevron-right"></i>
                        </div>
                      </div>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </header>

        <section className="bridge-section">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <a href="#" className="back-wallet">
                  {/* <i className="fas fa-chevron-left"></i> */}
                  {/* <span>Back to Wallet</span> */}
                </a>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row pt-50 pb-50">
              <div className="col-xl-3 col-lg-3 col-md-2 col-sm-12 col-12"></div>
              <div className="col-xl-6 col-lg-6 col-md-8 col-sm-12 col-12">
                <div className="details-bridge">
                  <div className="bridge-element">
                    <h3>ECN Bridge</h3>
                    <p>
                      The safe, fast and most secure way to bring cross-chain
                      assets to ECN chain.
                    </p>
                  </div>
                  <form onSubmit={this.onTransfer} className="transfer-form">
                    <div className="box-body">
                      <div className="sender-section">
                        <div className="from-part">
                          <span>FROM</span>
                        </div>
                        <div className="sender-input-details">
                          <div className="sender-label">
                            <h5>
                              {!this.state.isSwap ? "Ethereum" : "Binance"}
                            </h5>
                          </div>
                          <div className="input-value">
                            {/* <input type="text" className="currency-value" name="currency-value" id="currency-value" value="0" /> */}
                            <input
                              type="number"
                              className="currency-value"
                              value={this.state.swapValueEthToBsc}
                              onChange={(event) =>
                                this.setState({
                                  swapValueEthToBsc: event.target.value,
                                })
                              }
                            />

                            <a className="mm-button">MAX</a>
                            {!this.state.isSwap ? (
                              <a
                                className="select-box m-button size--medium selectmenu"
                                style={{ width: 125 }}
                                data-toggle="modal"
                                data-target="#differentToken"
                              >
                                <img
                                  src="assets/img/favicon.png"
                                  className="img-responsive"
                                />
                                <span>ECN</span>
                                {/* <i className="fas fa-chevron-down"></i> */}
                              </a>
                            ) : (
                              <a
                                className="select-box m-button size--medium selectmenu"
                                style={{ width: 125 }}
                                data-toggle="modal"
                                data-target="#differentToken"
                              >
                                <img
                                  src="assets/img/favicon.png"
                                  className="img-responsive"
                                />
                                <span>ECN</span>
                              </a>
                            )}
                            {/* <div className="select-box m-button size--medium selectmenu" style={{ width: 180}} >
                                              <Select 
                              value={selectedOption}
                              onChange={this.handleChange}
                              options={options}
                            />
                            </div> */}
                          </div>
                          <div className="balance-sender-amount">
                            <div className="row">
                              <div className="col-6">
                                <div className="dollar-amount">
                                  {/* <h5>$ 0.000</h5> */}
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="current-amount">
                                  {/* <span>Available Balance:</span>
                                          <span>0.000 ETH</span> */}
                                  {/* {
                                              !this.state.isSwap ? <span  className="" >Available Balance: {this.state.balance_ETH} ECN</span> :<span  className=""> Available Balance: {this.state.balance_BSC} ECN</span> 
                                            } */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mid-section">
                        <a className="btn btn-primary rounded-circle">
                          <img
                            src="assets/img/switch-logo.svg"
                            className="img-responsive"
                            onClick={() =>
                              this.setState({ isSwap: !this.state.isSwap })
                            }
                          />
                          {/* <img src="{assets/img/switch-logo.svg}" className="img-responsive" /> */}
                        </a>
                        <span>Switch</span>
                      </div>
                      <div className="receiver-section">
                        <div className="to-part">
                          <span>TO</span>
                        </div>
                        <div className="balance-receiver-amount">
                          <div className="row">
                            <div className="col-6">
                              <div className="to-heading">
                                <h5>
                                  {this.state.isSwap ? "Ethereum" : "Binance"}
                                </h5>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="current-amount">
                                {/* <span>Available Balance:</span> */}
                                {/* <span>0.000 ETH</span> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="transfer-mode pt-15 pb-15">
                      <p>
                        Transfer Mode: <span>PoS Bridge</span>
                      </p>
                    </div>
                    <div className="transfer-btn-parent">
                      <button type="submit" className="transfer-btn">
                        Transfer{" "}
                      </button>
                    </div>
                    <div className="">
                      {!this.state.isSwap ? (
                        <div className="label-button">
                          You Will Get Binance Smart Chain tokens to this
                          address
                        </div>
                      ) : (
                        <div className="label-button">
                          You Will Get Ethereum tokens to this address{" "}
                        </div>
                      )}
                      <h4>{this.state.accounts}</h4>
                    </div>
                  </form>

                  {/* <div className="points-guide">
                            <a  href="#" className="link-button">
                              How it Works?
                            </a>
                            <a  href="#" className="link-button">
                              FAQ
                            </a>
                            <a  href="#" className="link-button">
                              User Guide
                            </a>
                          </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="copyright">
            COPYRIGHTS @2021 ECN Global
            <img src="assets/img/favicon.png" className="copyright-img" />
          </div>
        </section>
      </div>
    );
  }
}

export default App;
